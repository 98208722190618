<template>
  <v-container>
    <v-row>
      <v-col class="pa-0">
        <v-carousel v-model="model" :show-arrows="false" fluid height="auto">
          <v-carousel-item
            v-for="color in colors"
            :key="color"
          >
            <v-sheet
              :color="color"
              tile
            >
              <v-row
                fluid
                class="fill-height"
                align="center"
                justify="center"
              >
                <v-img
                  contain
                  height="auto"
                  :src="require(`@/assets/aaa.png`)"
                >
                  <v-row no-gutters>
                    <v-col class="pa-12">
                      <span style="color: orange;">Best Games</span><br>
                      F O R T N I T E<br>
                      <v-btn>PLAY</v-btn>
                    </v-col>
                  </v-row>
                </v-img>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'Home',
    components: {
    },
    data () {
      return {
        faucetAddr: '',
        colors: [
          'primary',
          'secondary',
          'yellow darken-2',
          'red',
          'orange',
        ],
        model: 0,
      }
    },
    computed: {
      ...mapState('wallet', ['mainHost']),
    },
    methods: {
      async faucetRequest () {
        console.log('sss', this)
        var response = (
          await this.$http.get(this.mainHost + '/faucet?addr=' + this.faucetAddr)
        ).data
        console.log('req', response)
      },
    },
  }
</script>

<style>
  .v-carousel__controls {
    background: transparent !important;
  }
</style>
